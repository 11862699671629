<template>
  <div class="order-main">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="科目">
              <a-select allowClear placeholder="请选择" v-model="subjectSearch">
                <a-select-option :value="item.value" v-for="(item, index) in subjectArr" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="班级名称">
              <a-select
                v-model="formClassId"
                show-search
                allowClear
                :filter-option="filterOption"
                option-filter-prop="children"
                placeholder="请选择"
              >
                <a-select-option :value="item.uuid" :key="index" v-for="(item, index) in classNameArr">
                  {{ item.className }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="班级类型">
              <a-select allowClear placeholder="请选择" v-model="classType">
                <a-select-option :value="item.value" v-for="(item, index) in classTypeArr" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="授课老师">
              <a-select v-model="teacherIdSearch" show-search @search="searchTeacher" :filter-option="false" allowClear>
                <a-select-option v-for="item in teacherOptions" :key="item.uuid" :value="item.uuid">
                  {{ item.fullName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="课节状态">
              <a-select allowClear placeholder="请选择" mode="multiple" v-model="scheduleStatuses">
                <a-select-option :value="item[0]" v-for="item in Object.entries(ScheduleStatusEnumText)" :key="item[0]">
                  {{ item[1] }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="学生课节状态">
              <a-select allowClear placeholder="请选择" mode="multiple" v-model="studentStatuses">
                <a-select-option :value="item.value" v-for="(item, index) in studentStatusArr" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="上课日期(学生)">
              <a-range-picker
                @change="
                  (data, dateString) => {
                    onChangeTableDate('student', data, dateString);
                  }
                "
                v-model="queryClassDateArr"
                format="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="上课日期(北京)">
              <a-range-picker
                @change="
                  (data, dateString) => {
                    onChangeTableDate('city', data, dateString);
                  }
                "
                v-model="queryCityDateArr"
                format="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="上课平台">
              <a-select allow-clear v-model="platform" placeholder="请选择">
                <a-select-option v-for="item in platformsArr" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24" style="float: right; text-align: right">
            <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
              <a-button type="primary" style="margin-right: 10px" @click="initSearchData('search')">查询</a-button>
              <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="order-table">
      <a-table
        :columns="columns"
        :data-source="tableData"
        @change="handleTableChange"
        :pagination="initPagination"
        :loading="spinning"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <a slot="level" slot-scope="text">{{ text }}</a>
        <template slot="userName" slot-scope="userName, record">
          <span style="font-weight: 600; font-size: 16px">{{ userName }}</span>
          <p>{{ record.role }} ({{ record.department }})</p>
        </template>
        <template slot="productTags" slot-scope="productTags, record">
          <span v-for="i in record.productTags" :key="i">
            {{
              initTabsList(i, tabsList).length !== 0 && ['CHINESE', 'MATH', 'ENGLISH'].find((item) => i == item)
                ? initTabsList(i, tabsList)[0].label
                : ' '
            }}
          </span>
        </template>
        <template slot="courseType" slot-scope="courseType">
          <span> {{ initOptionsType(courseType, courseTypeArr) }} </span>
        </template>
        <template slot="scheduleStatus" slot-scope="scheduleStatus">
          <span> {{ ScheduleStatusEnumText[scheduleStatus] }} </span>
        </template>
        <template slot="feedbackStatus" slot-scope="_, record">
          <span> {{ record.feedbackStatus ? '已阅' : '否' }} </span>
        </template>
        <template slot="studentStatus" slot-scope="studentStatus">
          <span> {{ initOptionsType(studentStatus, studentStatusArr) }} </span>
        </template>
        <template slot="classType" slot-scope="classType">
          <span> {{ initOptionsType(classType, classTypeArr) }} </span>
        </template>

        <span slot="platform" slot-scope="text">{{ initOptionsType(text, platformsArr) }}</span>

        <template slot="scheduleName" slot-scope="scheduleName, record">
          <a-tag color="red" v-if="record.data && record.data.fromLeaveId">
            <span v-if="record.courseScheduleRespDTO.classType === 'PRIVATE'">补</span>
            <span v-if="record.courseScheduleRespDTO.classType === 'GROUP'">调</span>
          </a-tag>
          <span>{{ scheduleName }}</span>
          <span v-if="record.courseScheduleRespDTO.sectionNameStudent">{{
            `(${record.courseScheduleRespDTO.sectionNameStudent})`
          }}</span>
        </template>
        <router-link
          style="color: #3372fe"
          slot="formClassName"
          slot-scope="formClassName, record"
          :to="{ path: '/workoutSchedule/classDetail', query: { classId: record.courseScheduleRespDTO.formClassId } }"
        >
          {{ formClassName }}
        </router-link>

        <template slot="classTime" slot-scope="classTime, record">
          <div>
            <span v-if="record.courseScheduleRespDTO !== null">
              {{ record.courseScheduleRespDTO.startDateTime }}-{{
                record.courseScheduleRespDTO.endDateTime.substring(11, 16)
              }}</span
            >
            <span v-else>-</span>
          </div>
        </template>
        <template slot="studentClassTime" slot-scope="studentClassTime, record">
          <div>
            <span v-if="record.courseScheduleRespDTO !== null">
              {{ record.courseScheduleRespDTO.startStudentDateTime }}-{{
                record.courseScheduleRespDTO.endStudentDateTime.substring(11, 16)
              }}</span
            >
            <span v-else>-</span>
          </div>
        </template>
        <template slot="fullName" slot-scope="fullName, record">
          <a-popover placement="bottom">
            <template slot="content">
              <teacher-info
                :key="record.courseScheduleRespDTO.teacher.uuid"
                :teacherId="record.courseScheduleRespDTO.teacher.uuid"
                :subjectType="record.courseScheduleRespDTO.courseSectionRespDTO.data.subjectType"
              ></teacher-info>
            </template>
            <span style="cursor: pointer">
              {{ fullName }}
            </span>
          </a-popover>
        </template>
        <template slot="operation" slot-scope="operation, record" v-if="record.scheduleStatus !== 'CANCELLED'">
          <a-space>
            <a @click="playClass(record)" v-if="record.scheduleStatus === 'NORMAL'">监课</a>
            <a @click="playCourse(record)" v-if="record.scheduleStatus === 'COMPLETED'">回放</a>
            <FeedBackForm
              v-if="[ScheduleStatusEnum.COMPLETED, ScheduleStatusEnum.NORMAL].includes(record.scheduleStatus)"
              :studentProfile="studentProfile"
              :listRecord="record"
            />
            <a-dropdown>
              <a>更多</a>
              <a-menu slot="overlay">
                <a-menu-item key="1">
                  <a @click="teachfocus(record)">课节重点</a>
                </a-menu-item>
                <a-menu-item key="2">
                  <a @click="homeworkFun(record)">课节作业</a>
                </a-menu-item>
                <a-menu-item key="3" v-if="record.scheduleStatus !== 'NORMAL'">
                  <a @click="lookReportFocus(record)">学习报告</a>
                </a-menu-item>
                <a-menu-item key="4">
                  <ViewFeedBack :listRecord="record" />
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-space>
        </template>
      </a-table>
    </div>
    <component
      :sortValueSel="sortValueSel"
      :is="currentView"
      :homeworkTemplate="homeworkTemplate"
      :studentReview="studentReview"
      :studentId="studentId"
      :checkData="checkData"
      :teacherId="teacherId"
      :subject="subject"
      :courseScheduleId="courseScheduleId"
      @openCheckOperation="openCheckOperation"
    ></component>
    <!-- 中文课后作业未提交 -->
    <a-drawer title="查看详情" placement="right" width="700" :closable="true" :visible="checkVisible" @close="cancal">
      <CheckHomeWork
        :subject="subject"
        :checkData="checkData"
        :studentReview="studentReview"
        :scheduleData="scheduleData"
        :homeworkTemplate="homeworkTemplate"
      />
    </a-drawer>

    <!--学习报告-->
    <a-drawer
      v-if="visibleReport"
      :title="reportTitle"
      placement="right"
      width="700"
      :visible="visibleReport"
      @close="onCloseReportTC"
    >
      <StudyReport :subject="subject" :reportInfoData="reportInfoData"></StudyReport>
    </a-drawer>
    <!--教学重点-->
    <a-drawer title="教学重点" placement="right" width="700" :visible="visibleTeachfocus" @close="onCloseTeacherTC">
      <TeachingFocus v-if="visibleTeachfocus" :subject="subject" :teachInfoData="teachInfoData"></TeachingFocus>
    </a-drawer>
  </div>
</template>
<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { CheckHomeWork } from '@wk/tch-sc';
import { getCourseScheduleList, courseHomeWork, getCourseReport, queryClassNameNew } from '@/api/headTeacher';
import defaultImg from '@/assets/service_pic_head.png';
import StudyReport from './StudyReport.vue';
import TeachingFocus from './TeachingFocus.vue';
import CheckOperation from '@/views/operation/CheckOperation';
import ReviewOperation from '@/views/operation/ReviewOperation';
import { postReq, queryPlayCourse } from '@/api/schedule';
import TeacherInfo from '@/views/schedule/teacherInfo';
import { getReplayLink } from '@/api/common';
import ModalForm from '@/components/ModalForm/index.vue';
import FeedBackForm from './FeedBackForm.vue';
import { ScheduleStatusEnumText, ScheduleStatusEnum } from '@/enum';
import ViewFeedBack from './ViewFeedBack.vue';

const session = storage.createStore(sessionStorage);

export default {
  components: {
    CheckOperation,
    ReviewOperation,
    CheckHomeWork,
    StudyReport,
    TeachingFocus,
    TeacherInfo,
    ModalForm,
    FeedBackForm,
    ViewFeedBack,
  },
  props: ['studentProfile'],

  data() {
    return {
      platformsArr: [
        { label: 'Classin', value: 'CLASS_IN' },
        { label: '悟空教室', value: 'TALK_CLOUD' },
      ],
      platform: undefined,
      chinaTimeZone: false,
      queryCityDateArr: [],
      classType: undefined,
      queryClassDateArr: [],
      queryStartTime: '',
      queryEndTime: '',
      queryStartTimeCity: '',
      queryEndTimeCity: '',
      studentStatuses: [],
      scheduleStatuses: ['NORMAL'],
      teacherIdSearch: undefined,
      teacherOptions: [],
      classNameArr: [],
      classTypeArr: [
        { label: '班课', value: 'GROUP' },
        { label: '私教课', value: 'PRIVATE' },
      ],
      formClassId: undefined,
      subjectSearch: undefined,
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      studentId: '',
      courseSchedule: {},
      courseScheduleId: '',
      checkData: {},
      teacherId: '',
      studentReview: [],
      submited: [],
      noSubmited: [],
      homeworkTemplate: null,
      subject: '',
      scheduleData: {},
      checkVisible: false,
      sortValueSel: 0, // 默认选中预览题目tab
      currentView: null, // 组件名称
      reportTitle: '学习报告',
      reportInfoData: {},
      teachInfoData: {},
      visibleReport: false,
      visibleTeachfocus: false,
      spinning: false,

      defaultImg,
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '课节名称',
          dataIndex: 'courseScheduleRespDTO.scheduleName',
          key: 'scheduleName',
          scopedSlots: { customRender: 'scheduleName' },
          width: 180,
          ellipsis: true,
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#3372FE',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  this.$router.push({
                    path: '/workoutSchedule/scheduleDetailSub',
                    query: { id: record.courseScheduleId },
                  });
                },
              },
            };
          },
        },
        {
          title: '所属班级',
          dataIndex: 'courseScheduleRespDTO.formClassName',
          key: 'formClassName',
          scopedSlots: { customRender: 'formClassName' },
        },
        {
          title: '上课平台',
          dataIndex: 'courseScheduleRespDTO.platform',
          key: 'platform',
          scopedSlots: { customRender: 'platform' },
        },
        // {
        //   title: '课节类型',
        //   dataIndex: 'courseScheduleRespDTO.courseSectionRespDTO.course.courseType',
        //   key: 'courseType',
        //   filters: [
        //     { text: '标准课', value: 'STANDARD' },
        //     { text: '体验课', value: 'TRIAL' },
        //     { text: '公开课', value: 'PUBLIC' },
        //   ],
        //   scopedSlots: { customRender: 'courseType' },
        // },
        {
          title: '班级类型',
          dataIndex: 'courseScheduleRespDTO.classType',
          key: 'classType',

          scopedSlots: { customRender: 'classType' },
        },
        {
          title: '上课时间(班级时区)',
          dataIndex: 'courseScheduleRespDTO.startDateTime',
          key: 'startDateTime',
          sorter: true,
          scopedSlots: { customRender: 'classTime' },
        },
        {
          title: '上课时间(学生时区)',
          dataIndex: 'courseScheduleRespDTO.startStudentDateTime',
          key: 'startStudentDateTime',
          sorter: true,
          scopedSlots: { customRender: 'studentClassTime' },
        },
        {
          title: '授课老师',
          dataIndex: 'courseScheduleRespDTO.teacher.fullName',
          key: 'fullName',
          scopedSlots: { customRender: 'fullName' },
        },
        {
          title: '课节状态',
          dataIndex: 'scheduleStatus',
          key: 'scheduleStatus',
          // filters: [
          //   { text: '预排课', value: 'PRE_SCHEDULING' },
          //   { text: '待开课', value: 'NORMAL' },
          //   { text: '已完成', value: 'COMPLETED' },
          //   { text: '已取消', value: 'CANCELLED' },
          // ],
          scopedSlots: { customRender: 'scheduleStatus' },
        },
        {
          title: '反馈',
          scopedSlots: { customRender: 'feedbackStatus' },
        },
        {
          title: '学生课节状态',
          dataIndex: 'status',
          key: 'status',
          // filters: [
          //   { text: '待开课', value: 'NORMAL' },
          //   { text: '已完成', value: 'COMPLETED' },
          //   { text: '已取消', value: 'CANCELLED' },
          //   { text: '旷课', value: 'ABSENT' },
          // ],
          scopedSlots: { customRender: 'studentStatus' },
        },

        {
          title: '操作',
          width: 160,
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      studentStatusArr: [
        { label: '待开课', value: 'NORMAL' },
        { label: '已完成', value: 'COMPLETED' },
        { label: '已取消', value: 'CANCELLED' },
        { label: '旷课', value: 'ABSENT' },
      ],
      ScheduleStatusEnumText,
      ScheduleStatusEnum,
      courseTypeArr: [
        { text: '标准课', value: 'STANDARD' },
        { text: '体验课', value: 'TRIAL' },
        { text: '公开课', value: 'PUBLIC' },
      ],

      userInfo: storage.get('userInfo'),
      pageNum: 1,
      search: {
        tags: null,
        orderStatus: undefined,
        orderNumber: undefined,
      },
      searchData: null,
      tableData: null,
      selectedData: null,
      info: null,
      current: 0,
      pageSize: 10,
      allPaice: 0,
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      tags: undefined,
      loading: false,
      expand: {},
    };
  },
  created() {
    this.queryClassName();
  },
  mounted() {
    this.initSearchData('search');
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
    resetCondition() {
      this.studentStatuses = [];
      this.scheduleStatuses = [];
      this.formClassId = undefined;
      this.subjectSearch = undefined;
      this.classType = undefined;
      this.teacherIdSearch = undefined;
      this.queryStartTime = null;
      this.queryEndTime = null;
      this.queryStartTimeCity = null;
      this.queryEndTimeCity = null;
      this.queryClassDateArr = [];
      this.classType = undefined;
      this.queryCityDateArr = [];
      this.chinaTimeZone = false;
      this.platform = undefined;
      this.initSearchData('search');
    },
    onChangeTableDate(type, date) {
      console.log(type);
      console.log(date);
      if (type === 'city') {
        if (date.length > 0) {
          this.chinaTimeZone = true;
          this.queryStartTimeCity = date[0].format('YYYY-MM-DD');
          this.queryEndTimeCity = date[1].format('YYYY-MM-DD');
        } else {
          this.chinaTimeZone = false;
          this.queryStartTimeCity = null;
          this.queryEndTimeCity = null;
        }
      } else {
        this.chinaTimeZone = false;
        if (date.length > 0) {
          this.queryStartTime = date[0].format('YYYY-MM-DD');
          this.queryEndTime = date[1].format('YYYY-MM-DD');
        } else {
          this.chinaTimeZone = true;
          this.queryStartTime = null;
          this.queryEndTime = null;
        }
      }
    },
    searchTeacher(e) {
      postReq('/api/admin/teacher/search/1', { fullName: e }).then((res) => {
        this.teacherOptions = res.data.content;
      });
    },
    // 查询班级名称
    queryClassName() {
      const params = {
        studentId: this.studentProfile?.uuid,
      };
      queryClassNameNew(params).then((res) => {
        this.classNameArr = res.data;
      });
    },
    // 获取学习报告
    getCourseReport() {
      const params = {
        courseScheduleId: this.courseSchedule?.courseScheduleId,
        studentId: this.studentProfile?.uuid,
      };
      getCourseReport(params).then((res) => {
        // const data = res.data.content;
        // if (data.ratingComment.ratingScore === -2 || data.ratingComment.ratingScore === -1) {
        //   this.$message.warning('暂无学习报告');
        //   return false;
        // }
        // this.reportInfoData = {
        //   scheduleName: this.courseSchedule.courseScheduleRespDTO.courseSectionRespDTO.sectionName,
        //   classType: this.courseSchedule.courseScheduleRespDTO.classType === 'PRIVATE' ? '私教课' : '班课',
        //   timeValue: `${this.$moment(this.courseSchedule.courseScheduleRespDTO.startDateTime).format(
        //     'YYYY年MM月DD日',
        //   )} ${this.$moment(this.courseSchedule.courseScheduleRespDTO.startDateTime).format(
        //     'dddd',
        //   )}${this.courseSchedule.courseScheduleRespDTO.startDateTime.substr(
        //     11,
        //   )}-${this.courseSchedule.courseScheduleRespDTO.endDateTime.substr(11)}`,
        //   structure: this.courseSchedule.courseScheduleRespDTO.courseSectionRespDTO.structure,
        //   ratingComment: data.ratingComment, // 本课评价
        //   classPerformance: data.classPerformance, // 课堂评价
        //   activeData: data.activeData, // 课堂数据
        //   teacherName: `${this.courseSchedule.courseScheduleRespDTO.teacher.fullName}（${this.courseSchedule.courseScheduleRespDTO.teacher.code})`,
        // };
        // this.visibleReport = true;
        const { answerMachine, studentInteractiveStatistics } = res.data.content;
        if (
          !studentInteractiveStatistics &&
          (answerMachine.ratingComment.ratingScore === -2 || answerMachine.ratingComment.ratingScore === -1)
        ) {
          this.$message.warning('暂无学习报告');
          return false;
        }
        this.reportInfoData = {
          scheduleName: this.courseSchedule.courseScheduleRespDTO.courseSectionRespDTO.sectionName,
          classType: this.courseSchedule.courseScheduleRespDTO.classType === 'PRIVATE' ? '私教课' : '班课',
          timeValue: `${this.$moment(this.courseSchedule.courseScheduleRespDTO.startDateTime).format(
            'YYYY年MM月DD日',
          )} ${this.$moment(this.courseSchedule.courseScheduleRespDTO.startDateTime).format(
            'dddd',
          )}${this.courseSchedule.courseScheduleRespDTO.startDateTime.substr(
            11,
          )}-${this.courseSchedule.courseScheduleRespDTO.endDateTime.substr(11)}`,
          structure: this.courseSchedule.courseScheduleRespDTO.courseSectionRespDTO.structure,
          ratingComment: answerMachine.ratingComment, // 本课评价
          classPerformance: answerMachine.classPerformance, // 课堂评价
          activeData: answerMachine.activeData, // 课堂数据
          teacherName: `${this.courseSchedule.courseScheduleRespDTO.teacher.fullName}（${this.courseSchedule.courseScheduleRespDTO.teacher.code})`,
          studentInteractiveStatistics,
        };
        this.visibleReport = true;
      });
    },
    lookReportFocus(record) {
      this.courseSchedule = record;
      this.reportInfoData = {};
      this.subject = record.courseScheduleRespDTO.courseSectionRespDTO.course.subject;
      this.reportTitle = this.subject === 'MATH' ? 'Teaching Focus' : '学习报告';
      this.getCourseReport();
    },

    onCloseReportTC() {
      this.visibleReport = false;
    },
    openCheckOperation(sort) {
      // 从题目详情点击进去预览题目，选中对应的tab
      this.sortValueSel = sort;
      // 打开预览作业
      this.currentView = 'CheckOperation';
      this.homeworkTemplate = session.get('homeworkTemplate');
    },
    homeworkFun(record) {
      this.subject = record?.courseScheduleRespDTO?.courseSectionRespDTO?.course?.subject;
      this.courseSchedule = record;
      (this.courseScheduleId = record.courseScheduleId), (this.teacherId = record.courseScheduleRespDTO.teacher.uuid);
      this.studentId = this.studentProfile.uuid;
      this.initCourseHomeWork();
    },
    // 获取预览作业
    initCourseHomeWork() {
      const params = {
        courseScheduleId: this.courseSchedule.courseScheduleId,
        courseSectionId: this.courseSchedule.courseScheduleRespDTO.courseSectionId,
        studentId: this.studentProfile.uuid,
        teacherId: this.courseSchedule.courseScheduleRespDTO.teacher.uuid,
      };
      console.log('entry2');

      courseHomeWork(params).then((res) => {
        this.studentReview = res.data.content;
        // 判断课后作业有没有发布，发布了，判断学生是否提交了作业，提交了展示提交的作业，否则展示预览作业
        if (this.studentReview.publish) {
          const p = new URLSearchParams(params);
          window.open(`/app-react/homework-preview?${p.toString()}`);
          return;
          // 预览老师发布的作业
          this.checkData = res.data.content?.homeworkTemplate?.homeworkLink;
          this.homeworkTemplate = res.data.content?.homeworkTemplate;
          // session.set('homeworkTemplate',this.homeworkTemplate)
          // session.set('checkData',this.checkData)
          this.submited = this.studentReview.stuSchedules.filter((item) => {
            return item.student.submitStatus;
          });
          this.noSubmited = this.studentReview.stuSchedules.filter((item) => {
            return !item.student.submitStatus;
          });
          if (this.studentReview?.student?.caSubmitStatus) {
            // 预览学生提交的作业,里面也要展示未提交的
            this.currentView = 'ReviewOperation';
          } else {
            this.sortValueSel = 0;
            this.checkVisible = true;
          }
        }
        this.$message.warning('暂无作业发布');
      });
    },
    onCloseTeacherTC() {
      this.visibleTeachfocus = false;
    },
    cancal() {
      this.currentView = null;
      this.checkVisible = false;
    },
    teachfocus(record) {
      console.log(record);
      this.teachInfoData = {
        scheduleName: record.courseScheduleRespDTO.courseSectionRespDTO.sectionName,
        classType: record.courseScheduleRespDTO.classType === 'PRIVATE' ? '私教课' : '班课',
        timeValue: `${this.$moment(record.courseScheduleRespDTO.startDateTime).format('YYYY年MM月DD日')} ${this.$moment(
          record.courseScheduleRespDTO.startDateTime,
        ).format('dddd')}${record.courseScheduleRespDTO.startDateTime.substr(
          11,
        )}-${record.courseScheduleRespDTO.endDateTime.substr(11)}`,
        structure: record.courseScheduleRespDTO.courseSectionRespDTO.structure,
        teacherName: `${record.courseScheduleRespDTO.teacher.fullName}（${record.courseScheduleRespDTO.teacher.code})`,
        sectionId: record.courseScheduleRespDTO.courseSectionId,
        description: record.courseScheduleRespDTO.courseSectionRespDTO.description,
      };
      this.visibleTeachfocus = true;
    },
    // 课程回放
    async playCourse(record) {
      try {
        const { data } = await getReplayLink(record.courseScheduleId);
        const videoInfo = data?.[0];
        if (videoInfo?.type === 'HTML_URL') {
          window.open(videoInfo?.link);
        } else {
          window.open(`/lessonReplay?courseScheduleId=${record.courseScheduleId}`, '_blank');
        }
      } catch {
        window.open(`/lessonReplay?courseScheduleId=${record.courseScheduleId}`, '_blank');
      }
    },
    // 监课
    playClass(record) {
      // 上课平台是classIn，直接打开
      if (record.courseScheduleRespDTO.platform === 'CLASS_IN') {
        window.open(record.courseScheduleRespDTO.liveUrl, '_blank');
      } else if (record.courseScheduleRespDTO.platform === 'TALK_CLOUD') {
        this.queryPlayCourse(record.courseScheduleId);
      }
    },
    queryPlayCourse(courseScheduleId) {
      const params = {
        courseScheduleId,
      };
      queryPlayCourse(params).then((res) => {
        window.open(res.data.content.inspectorLink, '_blank');
      });
    },

    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
    lookDetail(data) {
      this.$router.push({
        path: '/orderDetail',
        query: {
          orderId: data.uuid,
          approveStatus: data.approveStatus,
          orderStatus: data.orderStatus,
          logisticsStatus: data.logisticsStatus,
        },
      });
    },

    initSearchData(act, searchData) {
      if (act === 'search') {
        this.pageNum = 1;
      }
      const params = {
        chinaTimeZone: this.chinaTimeZone,
        formClassId: this.formClassId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        studentId: this.studentProfile?.uuid,
        statuses: this.studentStatuses,
        scheduleStatuses: this.scheduleStatuses,
        startDateFrom: this.chinaTimeZone ? this.queryStartTimeCity : this.queryStartTime,
        startDateTo: this.chinaTimeZone ? this.queryEndTimeCity : this.queryEndTime,
        subject: this.subjectSearch,
        classType: this.classType,
        teacherId: this.teacherIdSearch,
        studentTimeZone: this.studentProfile?.timezoneStd,
        platform: this.platform,
        ...searchData,
      };
      console.log(searchData);
      if (!searchData?.orderBy) {
        params.orderBy = 'courseSchedule.startDateTime asc';
      }
      this.spinning = true;
      getCourseScheduleList(params)
        .then((res) => {
          this.spinning = false;
          const { data } = res;
          this.tableData = res.data.content;
          this.current = data.currentPage;
          this.pageSize = data.pageSize;
          this.initPagination.total = data.totalCount;
          this.initPagination.pageSize = data.pageSize;
          this.initPagination.current = data.currentPage;
        })
        .finally(() => {
          this.spinning = false;
        });
    },

    handleTableChange(pagination, filters, sorter) {
      this.pageNum = pagination.current;
      this.initPagination.current = pagination.current;
      this.initPagination.pageSize = pagination.pageSize;
      this.pageSize = pagination.pageSize;

      const search = {
        // statuses: filters.status && filters.status.length > 0 ? filters.status : [],
        // scheduleStatuses: filters.scheduleStatus && filters.scheduleStatus.length > 0 ? filters.scheduleStatus : [],
        courseTypes: filters.courseType && filters.courseType.length > 0 ? filters.courseType : [],
      };
      let sort = '';
      if (sorter.order === 'descend') {
        sort = 'desc';
      } else if (sorter.order === 'ascend') {
        sort = 'asc';
      }
      if (sorter.order) {
        if (sorter.columnKey === 'startDateTime' || sorter.columnKey === 'startStudentDateTime') {
          search.orderBy = `courseSchedule.startDateTime ${sort}`;
        } else {
          search.orderBy = `${sorter.columnKey} ${sort}`;
        }
      }
      this.initSearchData('', search);
    },
    initTabsList(value, data) {
      return data.filter((i) => i.value === value);
    },
  },
};
</script>
<style lang="less" scoped>
.ant-btn-primary {
  background-color: #04cb94;
}
.operate-wrap {
  text-align: right;
  margin-bottom: 12px;
}

.operation {
  color: #3372fe;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
  }
}
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 105px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 70%;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
}
</style>
